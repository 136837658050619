import { clsx, type ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function toTitleCase(str: string): string {
  const words = str.split(' ');

  const capitalizedWords = words.map((word) => {
    const firstLetter = word.charAt(0).toUpperCase();
    const remainingLetters = word.slice(1).toLowerCase();

    return firstLetter + remainingLetters;
  });

  return capitalizedWords.join(' ');
}

export function extractCodeSnippet(
  codeContent: string,
  startLine: number,
  endLine: number,
) {
  return codeContent
    .split('\n')
    .slice(startLine - 1, endLine)
    .join('\n');
}

export function printLongDate(date: Date) {
  return date.getFullYear() > 1
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
      }).format(date)
    : 'N/A';
}

export function printShortDate(date: Date) {
  return date.getFullYear() > 1
    ? new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
      }).format(date)
    : 'N/A';
}

// From: https://www.builder.io/blog/relative-time
export function getRelativeTimeString(
  date: Date | number,
  lang = navigator.language,
): string {
  // Allow dates or times to be passed
  const timeMs = typeof date === 'number' ? date : date.getTime();

  // Get the amount of seconds between the given date and now
  const deltaSeconds = Math.round((timeMs - Date.now()) / 1000);

  // Array reprsenting one minute, hour, day, week, month, etc in seconds
  const cutoffs = [
    60,
    3600,
    86400,
    86400 * 7,
    86400 * 30,
    86400 * 365,
    Infinity,
  ];

  // Array equivalent to the above but in the string representation of the units
  const units: Intl.RelativeTimeFormatUnit[] = [
    'second',
    'minute',
    'hour',
    'day',
    'week',
    'month',
    'year',
  ];

  // Grab the ideal cutoff unit
  const unitIndex = cutoffs.findIndex(
    (cutoff) => cutoff > Math.abs(deltaSeconds),
  );

  // Get the divisor to divide from the seconds. E.g. if our unit is "day" our divisor
  // is one day in seconds, so we can divide our seconds by this to get the # of days
  const divisor = unitIndex ? cutoffs[unitIndex - 1] : 1;

  // Intl.RelativeTimeFormat do its magic
  const rtf = new Intl.RelativeTimeFormat(lang, { numeric: 'auto' });
  return rtf.format(Math.floor(deltaSeconds / divisor), units[unitIndex]);
}

export const MIN_DATE = new Date(-8640000000000000);
export const MAX_DATE = new Date(8640000000000000);
export function isBetweenInclusive<T extends number | Date>(
  value: T,
  from: T,
  to: T,
): boolean {
  return value >= from && value <= to;
}

export function createGitHubFileURL(
  owner: string,
  repo: string,
  ref: string,
  filePath: string,
  startLine?: number,
  endLine?: number,
) {
  const url = `https://github.com/${owner}/${repo}/blob/${ref}/${filePath}${startLine ? `#L${startLine}${endLine && `-L${endLine}`}` : ''}`;
  return url;
}
